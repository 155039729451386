<template>
  <layout-vertical>

    <router-view />

    <template #navbar="{ toggleVerticalMenuActive }">
      <navbar :toggle-vertical-menu-active="toggleVerticalMenuActive" />

      <b-modal id="announcements-modal" title="Announcements" >
        <b-card-text>
          <template v-for="announcement in announcementsForUser">
            <div v-if="$cookies.isKey('announcement_' + announcement.id) === false">
              <h5>{{ announcement.title }}</h5>
              <div>{{ announcement.announcement }}</div>
              <hr>
            </div>
          </template>
        </b-card-text>
        <template #modal-footer>
          <b-button variant="primary" @click="closeModal">
            Got It!
          </b-button>
        </template>
      </b-modal>

      <b-modal id="announcements-static-modal" title="All Announcements" >
        <b-card-text>
          <template v-for="announcement in announcementsForUser">
              <h5>{{ announcement.title }}</h5>
              <div>{{ announcement.announcement }}</div>
              <hr>
          </template>
        </b-card-text>
        <template #modal-footer>
          <b-button variant="primary" @click="closeStaticModal">
            Close
          </b-button>
        </template>
      </b-modal>

    </template>

    <!-- <app-customizer
      v-if="showCustomizer"
      slot="customizer"
    /> -->
  </layout-vertical>
</template>

<script>
import LayoutVertical from '@core/layouts/layout-vertical/LayoutVertical.vue';
// import AppCustomizer from '@core/layouts/components/app-customizer/AppCustomizer.vue'
// import { $themeConfig } from '@themeConfig'
import Navbar from '../components/Navbar.vue';
import { mapActions, mapGetters } from "vuex";
import { BModal, BButton, VBModal, BAlert, BCardCode, BCardText} from 'bootstrap-vue';
import Vue from 'vue';
import VueCookies from 'vue-cookies';
Vue.use(VueCookies);

export default {
  components: {
    // AppCustomizer,
    LayoutVertical,
    Navbar,
    BCardCode,
    BCardText,
    BButton,
    BModal,
    BAlert,
    VBModal
  },
  data() {
    return {
      // showCustomizer: $themeConfig.layout.customizer
    }
  },
  computed: {
    ...mapGetters({
      announcementsForUser: "announcements/announcementsForUser"
    })
  },
  methods: {
    ...mapActions({
      loadAnnouncementsForUser: "announcements/loadAnnouncementsForUser",
    }),
    closeStaticModal(){
      this.$bvModal.hide('announcements-static-modal');
    },
    closeModal(){

      this.announcementsForUser.map(function(announcement){
        $cookies.set('announcement_' + announcement.id, 'seen', { expires: 7 });
      });

      this.$bvModal.hide('announcements-modal');
    },
    announcementOpen(){
      this.$bvModal.show('announcements-modal');
    },
    checkSeenAnnouncements(){
      let showAnnouncements = false;
      this.announcementsForUser.map(function(announcement){
        if( $cookies.isKey('announcement_' + announcement.id) === false ){
          showAnnouncements = true;
        }
      });
      if( showAnnouncements ){
        this.$bvModal.show('announcements-modal');
      }
    }
  },
  async mounted() {

    await this.loadAnnouncementsForUser().then(()=>{
      this.checkSeenAnnouncements();
    }
    ).catch(()=>{});
  }
}
</script>
